import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

const Social = props => {
  const data = useStaticQuery(graphql`
    query SocialQuery {
      allSocialJson {
        edges {
          node {
            name
            image
            link
          }
        }
      }
    }
  `);
  return (
    <div className="social">

    </div>
  );
};

export default Social;
